import React, { useEffect, useRef, useState } from 'react'
import ReactTable from 'react-table'
import SVGInline from 'react-svg-inline'
import { isNullOrWhitespace } from '../../shared/utility-functions/stringUtils'
import { PledgeComponent, CustomFakeSelectPledgeComponent, CustomPledgeComponent } from "./pledgeComponents.jsx"
import { UWPriortyFunds, OhioHealthFoundationFunds } from '../FundsData'
import { testPledges } from '../testData/pledges'
import PledgeSummary from './pledgeSummary.jsx'
import PostResult from './postResult.jsx'
import PledgeTypeRadio from './pledgeTypeRadio.jsx'
import LoadingElement from '../../shared/components/LoadingElement'
import { getWeGivePledges, sendWeGiveEmail } from '../../WeGive/services/we-give-service'
import AddIcon from '../../shared/assets/add.svg'
import CircleCheck from '../../shared/assets/check_circle.svg'
import { EmailValidator } from '../helpers/validator.jsx'
import './weGiveForm.scss'
import '../../../node_modules/react-table/react-table.css'

const WeGiveForm = ({ selectedCharity, firstName, lastName, opid, emailAddress }) => {
    const fNDFunds = OhioHealthFoundationFunds.sort((a, b) => a.fundName.localeCompare(b.fundName))
    const uWAgencyIds = UWPriortyFunds.map(i => i.fundId)
    uWAgencyIds.push('-1')

    const campaignYear = 2025
    const oneTimeDeductionCount = 1
    const perPayPeriodDeductionCount = 26
    const perPayDeductionStartDate = new Date(2025, 0)
    const oneTimeDeductionStartDate = new Date(2025, 2, 7)
    const perPayPeriodSummaryNote = `Recurring payroll deduction starting ${perPayDeductionStartDate.toLocaleDateString('en-us', { year: 'numeric', month: 'long' }) }`
    const oneTimeSummaryNote = `Single payroll deduction made on ${oneTimeDeductionStartDate.toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: '2-digit' })}`
    const perPayDeductionNote = '** Reminder: Your pledge will be renewed annually until you opt out.'
    const maxFNDPriorityPledgeCount = 1
    const maxFNDPledgeCount = Math.abs(fNDFunds.length - maxFNDPriorityPledgeCount)
    const maxUWPledgeCount = useRef(1)
    const maxUWCustomPledgeCount = useRef(2)
    const emptyPledge = {
        "id": 0,
        "firstName": "",
        "lastName": "",
        "opid": "",
        "emailAddress": null,
        "workSite": null,
        "fundId": "-1",
        "fundName": "",
        "fundCode": "",
        "donationCount": 0,
        "donationAmount": 0,
        "isPriority": false, 
        "trackingId": -1
    }
    const charityOptions = [
        {
            index: 1,
            name: 'OhioHealth Foundation'
        },
        {
            index: 2,
            name: 'United Way'
        },
        {
            index: 3,
            name: '' // All option names concatenated dynamically
        }
    ]
    const deductionTypeLabels = {
        perPayPeriodStateLabel: `Per Pay Period Deduction (starting ${perPayDeductionStartDate.toLocaleDateString('en-us', { year: 'numeric', month: 'long'})})`,
        oneTimeStateLabel: `One Time Deduction (on ${oneTimeDeductionStartDate.toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: '2-digit' })})`
    }
    const questionsEmailLink = { 
        label: 'WEGive@OhioHealth.com',
        link: 'mailto:wegive@ohiohealth.com'
    }
    const [fNDPledges, setFNDPledges] = useState([])
    const [uWPledges, setUWPledges] = useState([])
    const [firstFNDPriorityPledge, setFirstFNDPriorityPledge] = useState({})
    const [pledgeType, setPledgeType] = useState(null)
    const [hydrated, setHydrated] = useState(false)
    const [loading, setLoading] = useState(true)
    const [alternateEmail, setAlternateEmail] = useState('')
    const [submittedPledges, setSubmittedPledges] = useState({})
    const [modalOpen, setModalOpen] = useState(false)
    const [currentModalPledge, setCurrentModalPledge] = useState(-1)
    const [modalFunds, setModalFunds] = useState([])
    const [pledgeErrors, setPledgeErrors] = useState([])
    const [submitted, setSubmitted] = useState(false)
    const [hasFormErrors, setHasFormErrors] = useState(false)
    const [postSuccess, setPostSuccess] = useState(null)
    const submitRetries = useRef(0)
    const submitting = useRef(false)

    const getCharityHeading = () => {
        let charityHeading = ''

        if (selectedCharity === 3) {
            const formatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' })

            charityHeading = formatter.format(charityOptions.filter(i => !isNullOrWhitespace(i.name)).map(i => i.name))
        }
        else {
            const charityOption = charityOptions.find(i => i.index === selectedCharity)

            charityHeading = charityOption ? charityOption.name : ''
        }

        return `${charityHeading + ' '}Payroll Deduction`
    }

    const collateByFundId = (items) => {
        if (items || items.length > 0) {
            const groups = groupBy(items, 'fundId')

            return Object.keys(groups).map(fundId => {
                const templateItem = groups[fundId][0]

                return {
                    "id": templateItem.id ? templateItem.id : 0,
                    "firstName": templateItem.firstName,
                    "lastName": templateItem.lastName,
                    "opid": templateItem.opid,
                    "emailAddress": templateItem.emailAddress,
                    "workSite": templateItem.workSite,
                    "fundId": fundId,
                    "fundName": templateItem.fundName,
                    "fundCode": templateItem.fundCode,
                    "donationCount": 0,
                    "donationAmount": groups[fundId].reduce((acc, item) => acc + parseFloat(parseFloat(item.donationAmount).toFixed(2)), 0),
                    "isPriority": false
                }
            })
        }
        return []
    }

    const groupBy = (objArray, objProperty) => {
        return objArray.reduce((acc, item) => {
            const objKey = item[objProperty];
            if(!acc[objKey]){
                acc[objKey] = []
            }
            acc[objKey].push(item)

            return acc
        }, {})
    }

    const hydrate = (items, fundType) => {
        let pledges = [...items]
        let hydrated = []

        if(pledges.length === 0) {
            hydrated = normalize(pledges, fundType)
        }
        else{
            if(fundType === 'FND'){
                const priorityFundIds = fNDFunds.filter(i => i.priorityInfo.isPriority).map(i => i.fundId)

                hydrated = pledges.map(i => ({ ...i, isPriority: priorityFundIds.includes(i.fundId) }))
                hydrated = normalize(hydrated, fundType)
            }
            else if(fundType === 'UW'){
                hydrated = pledges.map(i => ({ ...i, isPriority: false }))
                hydrated = normalize(hydrated, fundType)
            }
        }

        return hydrated.map((i, j) => ({
            ...i,
            trackingId: j
        }))
    }    

    const validate = (pledgeList) => {
        const errors = []
        const uWFundIdRegEx = /^[0-9]{2}(-[0-9]{7})$/

        pledgeList.forEach(i => errors.push({
            fundId: i.fundId,
            errors: {
                fundId: i.fundCode === 'FND' ? isNullOrWhitespace(i.fundId) || ['0', '-1'].includes(i.fundId) : (i.fundCode === 'UW' && i.fundId !== '-1') ? !uWFundIdRegEx.test(i.fundId) : false,
                fundName: !isNullOrWhitespace(i.fundId) && !['0', '-1', '-2'].includes(i.fundId) && isNullOrWhitespace(i.fundName),
                amount: !isNullOrWhitespace(i.fundId) && !['0', '-1', '-2'].includes(i.fundId) && (i.donationAmount === NaN || i.donationAmount < 0.01)
            }
        }))

        return {
            valid: validateForm() && errors.every(i => !i.errors.fundId && !i.errors.fundName && !i.errors.amount),
            errors
        }
    }

    const validateForm = () => {
        return pledgeType && (isNullOrWhitespace(alternateEmail) || EmailValidator(alternateEmail))
    }

    const handleSubmit = (isResubmit = false) => {
        submitting.current = true

        if (isResubmit) {
            setSubmittedPledges(pledgeDataDto)
        }
        else {
            const allPledges = []

            if([1, 3].includes(selectedCharity)){
                allPledges.push(...(fNDPledges.filter(i => !isNullOrWhitespace(i.fundId) && !['0', '-1', '-2'].includes(i.fundId))))
            }

            if([2, 3].includes(selectedCharity)){
                allPledges.push(...(uWPledges.filter(i => !isNullOrWhitespace(i.fundId) && !['0', '-1', ''].includes(i.fundId))))
            }

            const validatedPledges = validate(allPledges)

            if (validatedPledges.valid) {
                const dtoPledges = allPledges.map(i => ({
                    id: i.id,
                    fundId: i.fundId,
                    fundName: i.fundName,
                    fundCode: i.fundCode,
                    donationCount: i.donationCount,
                    donationAmount: parseFloat(parseFloat(i.donationAmount).toFixed(2))
                }))
                const totalPledgeAmount = dtoPledges.map(i => i.donationAmount).reduce((acc, item) => acc + item, 0)
                const pledgeDataDto = {
                    firstName: firstName,
                    lastName: lastName,
                    emailAddress: isNullOrWhitespace(alternateEmail) ? emailAddress : alternateEmail,
                    opid: opid,
                    totalAmount: totalPledgeAmount,
                    paymentBreakdown: parseFloat(parseFloat(pledgeType && pledgeType === 'split' ? (totalPledgeAmount / perPayPeriodDeductionCount) : (totalPledgeAmount / oneTimeDeductionCount)).toFixed(2)),
                    pledges: dtoPledges,
                    workSite: '',
                    campaignYear: campaignYear
                }
                setSubmittedPledges(pledgeDataDto)
                setHasFormErrors(false)
            }
            else {
                setPledgeErrors(validatedPledges.errors)
                setHasFormErrors(true)
            }
        }
        submitting.current = false
        setSubmitted(true)
    }

    const resubmit = () => {
        submitRetries.current = submitRetries.current + 1

        handleSubmit(true)
    }

    const handleChange = (e) => { 
        const newValue = e.target.value

        switch (e.target.name) {
            case 'input-pledge-amount':
                updatePledgeAmount(e)
                break
            case 'select-fund':                
                updateFund(
                    e.target.getAttribute('data-code'),
                    parseInt(e.target.getAttribute('data-tracking'),10),
                    newValue
                )
                break
            case 'radio-pledge-type':
                updateDeductionType(e)
                break
            case 'input-custom-pledge-amount':
                updateCustomPledgeAmount(e)
                break
            case 'input-custom-fund-name':
                updateCustomFundName(e)
                break
            case 'input-custom-fund-id':
                updateCustomFundId(e)
                break
            case 'email':
                updateAlternateEmail(e)
                break
        } 
    }

    const updateAlternateEmail = (e) => {
        setAlternateEmail(e.target.value)
    }

    const updateDeductionType = (e) => {
        const deductionType = e.target.value
        const fndPledges = [...fNDPledges]
        const uwPledges = [...uWPledges]

        if (deductionType === 'split') {
            fndPledges.forEach(i => i.donationCount = perPayPeriodDeductionCount)
            uwPledges.forEach(i => i.donationCount = perPayPeriodDeductionCount)
        }
        else if (deductionType === 'single') {
            fndPledges.forEach(i => i.donationCount = oneTimeDeductionCount)
            uwPledges.forEach(i => i.donationCount = oneTimeDeductionCount)
        }

        setPledgeType(deductionType)
        setFNDPledges(fndPledges)
        setUWPledges(uwPledges)
    }

    const updateCustomPledgeAmount = (e) => {
        const fundCode = e.target.getAttribute('data-code')
        const trackedFundId = parseInt(e.target.getAttribute('data-tracking'), 10)
        const pledges = fundCode === 'FND' ? [...fNDPledges] : fundCode === 'UW' ? [...uWPledges] : []

        if (pledges && pledges.length > 0) {
            const pledge = pledges.find(i => i.trackingId === trackedFundId)

            if (pledge) {
                const amount = parseFloat(e.target.value)

                pledge.donationAmount = amount && amount !== NaN ? parseFloat(amount.toFixed(2)) : 0                

                if (fundCode === 'UW') {
                    setUWPledges(pledges)
                }
                else if (fundCode === 'FND') {
                    setFNDPledges(pledges)
                }
            }
        }
    }

    const updateCustomFundName = (e) => {
        const fundCode = e.target.getAttribute('data-code')
        const trackedFundId = parseInt(e.target.getAttribute('data-tracking'), 10)
        const pledges = fundCode === 'FND' ? [...fNDPledges] : fundCode === 'UW' ? [...uWPledges] : []

        if (pledges && pledges.length > 0) {
            const pledge = pledges.find(i => i.trackingId === trackedFundId)

            if (pledge) {
                pledge.fundName = e.target.value

                if (fundCode === 'UW') {
                    setUWPledges(pledges)
                }
                else if (fundCode === 'FND') {
                    setFNDPledges(pledges)
                }
            }
        }
    }

    const updateCustomFundId = (e) => {
        const fundCode = e.target.getAttribute('data-code')
        const trackedFundId = parseInt(e.target.getAttribute('data-tracking'), 10)
        const pledges = fundCode === 'FND' ? [...fNDPledges] : fundCode === 'UW' ? [...uWPledges] : []

        if (pledges && pledges.length > 0) {
            const pledge = pledges.find(i => i.trackingId === trackedFundId)

            if (pledge) {
                pledge.fundId = e.target.value

                if (fundCode === 'UW') {
                    setUWPledges(pledges)
                }
                else if (fundCode === 'FND') {
                    setFNDPledges(pledges)
                }
            }
        }
    }

    const updatePledgeAmount = (e) => {
        const fundCode = e.target.getAttribute('data-code')

        if (fundCode === 'FND') {
            const pledges = [...fNDPledges]
            const pledge = pledges.find(i => i.fundId === e.target.getAttribute('data-current'))
            const amount = parseFloat(e.target.value)

            if (pledge) {
                pledge.donationAmount = amount && amount !== NaN ? parseFloat(amount.toFixed(2)) : 0
                setFNDPledges(pledges)
            }
        }
        else if (fundCode === 'UW') {
            const pledges = [...uWPledges]
            const pledge = pledges.find(i => i.fundId === e.target.getAttribute('data-current'))
            const amount = parseFloat(e.target.value)

            if (pledge) {
                pledge.donationAmount = amount && amount !== NaN ? parseFloat(amount.toFixed(2)) : 0
                setUWPledges(pledges)
            }
        }
    }

    const updateFund = (fundCode, trackingId, newValue) => {
        let fund = {}
        let pledges = []

        if (fundCode === 'FND') {
            fund = fNDFunds.find(i => i.fundId === newValue)
            pledges = [...fNDPledges]
        }
        else if (fundCode = 'UW') {
            fund = UWPriortyFunds.find(i => i.fundId === newValue)
            pledges = [...uWPledges]
        }

        const pledge = pledges.find(i => i.trackingId === trackingId)

        pledge.fundId = fund.fundId
        pledge.fundName = fund.fundName
        pledge.isPriority = fund.priorityInfo ? fund.priorityInfo.isPriority : false

        if (fundCode === 'FND') {
            setFNDPledges(pledges)
        }
        else if (fundCode === 'UW') {
            setUWPledges(pledges)
        }  
    }

    const handleClick = (e) => {
        const fundCode = e.target.getAttribute('data-code')
        const action = e.target.getAttribute('data-action')
        const isCustom = e.target.getAttribute('data-custom').toLowerCase() === 'true'
        const trackingId = parseInt(e.target.getAttribute('data-tracking'), 10)

        switch (action) {
            case 'add':                
                addPledge(fundCode, isCustom)
                break
            case 'remove':
                removePledge(e.target.getAttribute('data-current'), fundCode)
                break
            case 'fake-select':
                openFundLookupTable(fundCode, trackingId)
                break
        }
    }

    const openFundLookupTable = (fundCode, trackingId) => {
        let funds = fundCode === 'FND' ? fNDFunds : fundCode === 'UW' ? UWPriortyFunds : []
        const pledgeFundIds = fundCode === 'FND' ? fNDPledges.map(i => i.fundId) : fundCode === 'UW' ? uWPledges.map(i => i.fundId) : []

        if (funds && funds.length > 0) {
            if (pledgeFundIds && pledgeFundIds.length > 0) {
                funds = funds.filter(i => !pledgeFundIds.includes(i.fundId))
            }
        }

        setModalOpen(true)
        setCurrentModalPledge(trackingId)
        setModalFunds(funds.map(i => ({fundName: `${i.fundName} - #${i.fundId}`})))
    }

    const closeFundLookupTable = (fundName) => {
        if (fundName) {
            const pledges = [...fNDPledges]
            const pledge = pledges.find(i => i.trackingId === currentModalPledge)

            if (pledge) {
                const fundInfo = fundName.split(' - #').map(i => i.trim())

                pledge.fundId = fundInfo[1]
                pledge.fundName = fundInfo[0]

                setFNDPledges(pledges)
            }
        }        
        setModalOpen(false)
    }

    const modalColumns = () => {
        const wildCardSymbols = ['#']

        return [{
            Header: props => <div className='table-header'>
                OhioHealth Funds
                <div className='close-lookup-table-btn' onClick={() => closeFundLookupTable()}>
                    <SVGInline className='remove-icon' svg={AddIcon} />
                </div>
            </div>,
            Filter: ({ filter, onChange }) => (
                <input type='text' className={'data-table-filter'}
                    //ref={filterInput => { this.filterInput = filterInput }}
                    placeHolder='Search Funds'
                    onChange={event => onChange(event.target.value)} value={filter ? filter.value : ''} />),
            filterMethod: (filter, row) => {
                let rowValues = row[filter.id].toLowerCase().trim().split(' ')
                let filterValues = filter.value.toLowerCase().trim().split(' ')

                wildCardSymbols.forEach((i) => {
                    let wildCardValues = rowValues.filter(j => j.startsWith(i.trim()))

                    if (wildCardValues && wildCardValues.length > 0) {
                        let replaceRegEx = new RegExp(i, 'g')

                        wildCardValues.map(j => rowValues.push(j.replace(replaceRegEx, '')))
                    }
                })

                return rowValues.some((word) => filterValues.some((filterValue) => word.startsWith(filterValue)))
            },
            accessor: 'fundName',
            Cell: props => <div className='look-up-cell' onClick={() => { closeFundLookupTable(props.value) }}>{props.value}</div>
        }]
    }

    const canAdd = (fundCode, isCustom) => {
        let result = false

        if (fundCode === 'FND') {
            result = fNDPledges.filter(i => !i.isPriority).length < maxFNDPledgeCount
        }
        else if(fundCode === 'UW') {
            result = isCustom ?
                uWPledges.filter(i => !uWAgencyIds.includes(i.fundId)).length < maxUWCustomPledgeCount.current :
                uWPledges.filter(i => uWAgencyIds.includes(i.fundId)).length < maxUWPledgeCount.current
        }
        return result
    }

    const addPledge = (fundCode, isCustom) => {
        if (canAdd(fundCode, isCustom)) {
            const newPledge = getNewPledgeItem(fundCode)

            if (fundCode === 'FND') {
                setFNDPledges([...fNDPledges, newPledge])
            }
            else if (fundCode === 'UW') {
                newPledge.fundId = isCustom ? '' : '-1'
                setUWPledges([...uWPledges, newPledge])
            }
        }
    }

    const removePledge = (fundId, fundCode) => {
        const pledges = fundCode === 'FND' ?
            [...fNDPledges] :
            fundCode === 'UW' ?
                [...uWPledges] :
                []
       
        const newPledges = pledges.filter(i => i.fundId !== fundId)

        if (fundCode === 'FND') {
            setFNDPledges(newPledges)
        }
        else {
            setUWPledges(newPledges)
        }
    }

    const refresh = (fundType) => {
        const pledges = fundType === 'FND' ? fNDPledges : fundType === 'UW' ? uWPledges : []
        const newPledge = getNewPledgeItem(fundType)

        if (fundType === 'FND') {
            if (pledges.filter(i => !i.isPriority).length === 0) {
                setFNDPledges([...pledges, newPledge])
            }
            else {
                let selectedPriorityPledge = firstFNDPriorityPledge.fundId ?
                    pledges.find(i => i.fundId === firstFNDPriorityPledge.fundId) :
                    pledges.find(i => i.isPriority)

                if(!selectedPriorityPledge){
                    newPledge.isPriority = true
                    newPledge.fundId = '-2'
                    selectedPriorityPledge = newPledge
                    setFNDPledges([...pledges, newPledge])
                }
                setFirstFNDPriorityPledge(selectedPriorityPledge)
            }
        }
        else if (fundType === 'UW') {
            if (pledges.filter(i => uWAgencyIds.includes(i.fundId)).length === 0) {
                newPledge.fundId = '-1'
                setUWPledges([...pledges, newPledge])
            }
            else if (pledges.filter(i => !uWAgencyIds.includes(i.fundId)).length === 0) {
                newPledge.fundId = ''
                setUWPledges([...pledges, newPledge])
            }
        }
    }

    const getNewPledgeItem = (fundCode) => {
        let newPledge = structuredClone(emptyPledge)
        const pledges = fundCode === 'FND' ? fNDPledges : fundCode === 'UW' ? uWPledges : []

        newPledge.fundCode = fundCode
        newPledge.donationCount = pledgeType === 'split' ? perPayPeriodDeductionCount : pledgeType === 'single' ? oneTimeDeductionCount : 0
        newPledge.trackingId = pledges.length > 0 ?
            Math.max(...pledges.map(i => i.trackingId)) + 1 :
            0

        return newPledge
    }

    const getFirstFNDPriorityPledge = (pledges) => {
        let priorityPledge = pledges.find(i => i.isPriority)

        if(!priorityPledge) {
            const newPledge = getNewPledgeItem('FND')

            newPledge.isPriority = true
            newPledge.fundId = '-2'
            priorityPledge = newPledge
        }
        return priorityPledge
    }

    const normalize = (pledgeList, fundCode) => {
        const normalizedList = []
        const pledges = pledgeList.filter(i => fundCode === 'FND' ? [...fNDPledges] : fundCode === 'UW' ? [...uWPledges] : [])
       
        if (pledges.length === 0) {
            const newPledgeItem0 = getNewPledgeItem(fundCode)
            const newPledgeItem1 = getNewPledgeItem(fundCode)

            if (fundCode === 'FND') {
                newPledgeItem1.isPriority = true
                newPledgeItem1.fundId = '-2'
            }
            else if (fundCode === 'UW') {
                newPledgeItem1.fundId = ''
            }
            normalizedList.push(newPledgeItem0)
            normalizedList.push(newPledgeItem1)
        }
        else {
            const newPledgeItem = getNewPledgeItem(fundCode)

            normalizedList.push(...pledges)

            if (fundCode === 'FND') {
                if (!pledges.some(i => i.isPriority)) {
                    newPledgeItem.isPriority = true
                    newPledgeItem.fundId = '-2'
                }
                else if (!pledges.some(i => !i.isPriority)) {
                    newPledgeItem.isPriority = false
                }
                normalizedList.push(newPledgeItem)
            }
            else if (fundCode === 'UW') {
                if (pledges.filter(i => i.fundId !== '').length === 0) {
                    newPledgeItem.fundId = '-1'
                }
                else if (!pledges.some(i => i.fundid === '')) {
                    newPledgeItem.fundId = ''
                }
                normalizedList.push(newPledgeItem)
            }
            
        }
        return normalizedList
    }

    useEffect(() => {
        if (hydrated && !loading) {
            refresh('FND')
        }
    }, [fNDPledges])

    useEffect(() => {
        if (hydrated && !loading) {
            refresh('UW')
        }
    }, [uWPledges])

    useEffect(() => {
        if (loading) {
            getWeGivePledges({ opid: opid, campaignYear: campaignYear })
                .then(response => {
                    const collated = collateByFundId(response.result)
                    const hydratedFNDPledges = hydrate(collated.filter(i => i.fundCode === 'FND'), 'FND')
                    const hydratedUWPledges = hydrate(collated.filter(i => i.fundCode === 'UW'), 'UW')
                    const firstFNDPriorityPledge = getFirstFNDPriorityPledge(hydratedFNDPledges)

                    setFNDPledges(hydratedFNDPledges)
                    setUWPledges(hydratedUWPledges)
                    setFirstFNDPriorityPledge(firstFNDPriorityPledge)
                    setHydrated(true)
                    setLoading(false)
                })
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        if (submitted) {
            sendWeGiveEmail(submittedPledges)
                .then(response => setPostSuccess(response.message === 'success'))
        }

    }, [submittedPledges])

    return (
        <div>
            {postSuccess === null && !loading && hydrated && 
                <div className='selection-area'>
                    <div className='heading-container'>
                        <h1 className='heading'>{getCharityHeading()}</h1>
                        <hr />
                    </div>
                    <div className='area'>
                        <div className='pledge-type-area'>
                            <div className='subheading'>
                                How would you like to make your pledge?
                                <div class="instruction">(choose one...)</div>
                            </div>
                            <div className={`pledge-type-options ${pledgeType === null && submitted ? 'element-error-highlight element-error-highlight-div' : ''}`}>
                                <PledgeTypeRadio
                                    checked={pledgeType === 'split'}
                                    name='radio-pledge-type'
                                    value='split'
                                    handleChange={handleChange}
                                >
                                    <div class="radio-btn-label">
                                        <span class="radio-button-heading">Per Pay Deduction</span>
                                        <span class="radio-button-subheading">{`(made each pay period starting ${perPayDeductionStartDate.toLocaleDateString('en-us', { year: 'numeric', month: 'long' })})`}</span>
                                        <div class="instruction">{perPayDeductionNote}</div>
                                    </div>
                                </PledgeTypeRadio>
                                <PledgeTypeRadio
                                    checked={pledgeType === 'single'}
                                    name='radio-pledge-type'
                                    value='single'
                                    handleChange={handleChange}
                                >
                                    <div class="radio-btn-label">
                                        <span class="radio-button-heading">One-Time Deduction</span>
                                        <span class="radio-button-subheading">{`(made on ${oneTimeDeductionStartDate.toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: '2-digit' })})`}</span>
                                    </div>
                                </PledgeTypeRadio>
                                <PledgeTypeRadio
                                    checked={[undefined, null, 'undefined', 'null'].includes(pledgeType)}
                                    name='radio-pledge-type'
                                    value='indeterminate'
                                    isHidden={true}
                                />
                            </div>
                            <div className={`field-error${pledgeType === null && submitted ? ' error-show' : ' error-hide'}`}>Please Select Either a One-Time or a Per Pay Period Pledge</div>
                        </div>
                        <div className='user-alert-area'>
                            <div className='user-alert'>
                                <span className='font-bold'>Please note:</span> <span>If the giving fields are pre-populated, thank you for making a recurring pledge during the 2023 WE Give campaign. If you want to keep these same selections, no further action is needed. If you would like to amplify your impact and increase your donation levels, please adjust your giving selections and resubmit. Thank you again for your support of WE Give.</span>
                            </div>
                        </div>
                        {(selectedCharity === 1 || selectedCharity === 3) &&
                            <div id='fNd-area'>
                                <PledgeComponent
                                    heading='Choose Your Designations'
                                    comboHeading='Choose Your OhioHealth Foundation Designations'
                                    label='OhioHealth Priority Funds'
                                    selectPlaceholderLabel='Select an OhioHealth Priority Fund'
                                    pledgeList={[firstFNDPriorityPledge]}
                                    selectOptions={fNDFunds.filter(i => i.priorityInfo.isPriority)}
                                    selectPlaceholderValue='-2'
                                    addPledgeControlOptions={{ classes: 'add-icon', icon: AddIcon, text: 'Add Another Fund', actionType: 'add' }}
                                    removePledgeControlOptions={{ classes: 'remove-icon', icon: AddIcon, text: 'Remove Fund', actionType: 'remove' }}
                                    deductionIntervalControlOptions={deductionTypeLabels}
                                    isComboDisplay={selectedCharity === 3}
                                    maxPledgeCount={maxFNDPriorityPledgeCount}
                                    handleChange={handleChange}
                                    handleClick={handleClick}
                                    submitted={submitted}
                                    pledgeErrors={pledgeErrors}
                                />
                                <div className='andor'>and/or</div>
                                <CustomFakeSelectPledgeComponent
                                    heading=''
                                    comboHeading=''
                                    countOffset={1}
                                    label='OhioHealth Foundation Funds'
                                    placeholderLabel='Search for an OhioHealth Fund'
                                    pledgeList={
                                        !firstFNDPriorityPledge.fundId || (firstFNDPriorityPledge.fundId && firstFNDPriorityPledge.fundId === '-2') ? 
                                        fNDPledges.filter(i => i.fundId !== '-2') : 
                                        fNDPledges.filter(i => i.fundId !== firstFNDPriorityPledge.fundId)
                                    }
                                    selectOptions={fNDFunds}
                                    addPledgeControlOptions={{ classes: 'add-icon', icon: AddIcon, text: 'Add Another Fund', actionType: 'add' }}
                                    removePledgeControlOptions={{ classes: 'remove-icon', icon: AddIcon, text: 'Remove Fund', actionType: 'remove' }}
                                    deductionIntervalControlOptions={deductionTypeLabels}
                                    maxPledgeCount={maxFNDPledgeCount}
                                    handleChange={handleChange}
                                    handleClick={handleClick}
                                    submitted={submitted}
                                    pledgeErrors={pledgeErrors}
                                />
                            </div>
                        }
                        {(selectedCharity === 2 || selectedCharity === 3) &&
                            <div id='uW-area' className={selectedCharity === 3 ? 'fundType-combo' : ''}>
                                <PledgeComponent
                                    heading='Choose your designations (two maximum)'
                                    comboHeading='Choose your United Way designations (two maximum)'
                                    label='United Way Funds'
                                    selectPlaceholderLabel='Select United Way Agency'
                                    pledgeList={uWPledges.filter(i => uWAgencyIds.includes(i.fundId))}
                                    selectOptions={UWPriortyFunds}
                                    selectPlaceholderValue='-1'
                                    addPledgeControlOptions={{ classes: 'add-icon', icon: AddIcon, text: 'Add Another Agency', actionType: 'add' }}
                                    removePledgeControlOptions={{ classes: 'remove-icon', icon: AddIcon, text: 'Remove Agency', actionType: 'remove' }}
                                    deductionIntervalControlOptions={deductionTypeLabels}
                                    maxPledgeCount={maxUWPledgeCount.current}
                                    isComboDisplay={selectedCharity === 3}
                                    handleChange={handleChange}
                                    handleClick={handleClick}
                                    submitted={submitted}
                                    pledgeErrors={pledgeErrors}
                                />
                                <div className='andor'>and/or</div>
                                <CustomPledgeComponent
                                    countOffset={uWPledges.filter(i => uWAgencyIds.includes(i.fundId)).length}
                                    controlOptions={{
                                        agencyLabel: 'United Way Funds',
                                        agencyPlaceholder: 'Organization Name',
                                        currencyLabel: 'Total Annual Amount',
                                        agencyIdLabel: 'EIN',
                                        agencyIdPlaceholder: 'Organization EIN (ex: 00-0000000)',
                                    }}
                                    addPledgeControlOptions={{ classes: 'add-icon', icon: AddIcon, text: 'Add Another Agency', actionType: 'add' }}
                                    removePledgeControlOptions={{ classes: 'remove-icon', icon: AddIcon, text: 'Remove Agency', actionType: 'remove' }}
                                    deductionIntervalControlOptions={deductionTypeLabels}
                                    pledges={uWPledges.filter(i => !uWAgencyIds.includes(i.fundId))}
                                    maxPledgeCount={maxUWCustomPledgeCount.current}
                                    handleClick={handleClick}
                                    handleChange={handleChange}
                                    submitted={submitted}
                                    pledgeErrors={pledgeErrors}
                                />
                                <div className='charity-navigator-link'>
                                    <a href='https://www.charitynavigator.org/' target='_blank' className='charity-navigator-link underline-link'>Search for funds with Charity Navigator</a>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='pledge-summary-area'>
                        <PledgeSummary
                            charityPledgeCollection={selectedCharity === 1 ?
                                [{ charityName: 'OhioHealth Foundation', charityAbbreviation: 'FND', pledges: fNDPledges }] :
                                selectedCharity === 2 ?
                                    [{ charityName: 'United Way', charityAbbreviation: 'UW', pledges: uWPledges }] :
                                    [
                                        { charityName: 'OhioHealth Foundation', charityAbbreviation: 'FND', pledges: fNDPledges },
                                        { charityName: 'United Way', charityAbbreviation: 'UW', pledges: uWPledges }
                                    ]
                            }
                            perPayPeriodValue={perPayPeriodDeductionCount}
                            perPayPeriodSummaryNote={perPayPeriodSummaryNote}
                            oneTimeValue={oneTimeDeductionCount}
                            oneTimeSummaryNote={oneTimeSummaryNote}
                        />
                        <div className='email-area'>
                            <div className="email-title">
                                A pledge confirmation will be sent to your OhioHealth email address.
                            </div>
                            <div className="email-text">
                                If you would like your pledge confirmation sent to a different email address, please enter it here:
                            </div>
                            <input
                                className={submitted && !isNullOrWhitespace(alternateEmail) && !EmailValidator(alternateEmail) ? 'element-error-highlight' : ''}
                                placeholder="Email address"
                                type="email"
                                name="email"
                                value={alternateEmail}
                                onBlur={handleChange}
                            />
                            <div className={`form-field-error${submitted && !isNullOrWhitespace(alternateEmail) && !EmailValidator(alternateEmail) ? ' error-show' : ' error-hide'}`}>Email is not Valid</div>
                        </div>
                    </div>
                    {submitting.current ?
                        <div className='loader-overlay'><LoadingElement /></div> :
                        <div className='submit-area'>
                            <div className={`form-field-error-center${submitted && hasFormErrors ? ' error-show' : ' error-hide'}`}>Please Correct Errors Above Before Submitting</div>
                            <button
                                className='btn btn-primary capitalize'
                                type='button'
                                onclick={() => handleSubmit()}
                            >
                                Make My Pledge
                            </button>
                        </div> 
                    }
                    <div className='questions-container'>
                        <span class='question-icon'>?</span>
                        <span>Questions: Email &nbsp;</span>
                        <a href={`mailto:${questionsEmailLink.link}`}>{questionsEmailLink.label}</a>
                    </div>
                    {modalOpen &&
                        <div className="modal shown-modal">
                            <div className='background'>
                                <div className='modal-content'>
                                    <div className='fund-lookup-table'>
                                        <ReactTable
                                            filterable
                                            showPagination={false}
                                            defaultPageSize={modalFunds.length}
                                            resizable={false}
                                            data={modalFunds}
                                            columns={modalColumns()}
                                            className={'-striped text-left'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
            {postSuccess === true &&
                <PostResult
                    ohLogo='https://www.ohiohealth.com/siteassets/global/oh_logo_blue-200x52.png'
                    weGiveLogo='https://www.ohiohealth.com/siteassets/campaigns/we-give/2021_wegive_graphic.jpg'
                    icon={CircleCheck}
                    iconParentClasses='big-icon'
                    iconClasses='circle-check'
                >
                    <h2 className='post-result-heading'>Thank you, {firstName} {lastName}.</h2>
                    <p className='message'>Your support makes amazing things possible, close to home and close to our hearts.</p>
                    <div className='pledge-info-text'>
                        {
                            pledgeType === 'single' ?
                                `Your one-time WE GIVE pledge of $${parseFloat(submittedPledges.paymentBreakdown).toFixed(2)} has been submitted.` :
                                `Your WE GIVE pledge of $${parseFloat(submittedPledges.paymentBreakdown).toFixed(2)} per pay has been submitted.`}
                    </div>
                    <div className='pledge-confirmation-text'>
                        A pledge confirmation for your records has been sent to {isNullOrWhitespace(alternateEmail) ? emailAddress : alternateEmail}
                    </div>
                    <div className='no-email'>Didn't receive your email?</div>
                </PostResult>
            }
            {postSuccess === false &&
                <PostResult
                    weGiveLogo='https://www.ohiohealth.com/siteassets/campaigns/we-give/2021_wegive_graphic.jpg'
                    icon={AddIcon}
                    iconParentClasses='big-icon rotate-icon'
                    iconClasses='remove-icon'
                >
                    <h2 className='post-result-heading'>Sorry, {firstName} {lastName}.</h2>
                    <p className='message'>An error prevented your pledge commitment from being properly completed.</p>
                    <div className='pledge-info-text'>
                        {`Your WE GIVE pledge of $${parseFloat(submittedPledges.totalAmount).toFixed(2)} has NOT been submitted.`}
                    </div>
                    <div className='no-email'>You can try to resubmit your pledge, or contact our support team by email.</div>
                    {submitting.current
                        ? <div className='loader-overlay'><LoadingElement /></div>
                        : (submitRetries.current < 1
                            ? <div className='btn btn-primary my-20p cursor-pointer' onClick={() => resubmit()}>RESUBMIT</div>
                            : <div className='retry-failed'>Retry failed. Please contact our support team.</div>)
                    }
                </PostResult>   
            }
        </div>
    )
}

export default WeGiveForm