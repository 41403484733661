import { Fragment } from 'react'
import './weGivePledgeRows.scss'
import { isNullOrWhitespace } from '../../shared/utility-functions/stringUtils.js'
import ActionButtonLink from './actionButton.jsx'
import ItemSeparator from './itemSeparator.jsx'

export const WeGivePledgeRow = ({
    pledgeCount,
    pledge,
    selectControlOptions,
    currencyOptions,
    removePledgeControlOptions,
    handleChange,
    handleClick,
    pledgeErrors,
    submitted}) => {
    return (
        <Fragment>
            {pledgeCount > 0 && <ItemSeparator width='80%' margin='25px auto' />}
            <div>
                <div className='pledgeGroup d-flex'>
                    <div>
                        <div className={`pledge-label${pledgeCount > 0 ? ' not-first' : ''}`}>
                            <div className='d-flex'>
                                <span>{selectControlOptions.label}</span>                   
                                <span className='label-count'>&nbsp;({pledgeCount + 1})</span>
                            </div>
                        </div>
                        <select
                            className={`${selectControlOptions.classes}${submitted && pledgeErrors && pledgeErrors.errors.fundId ? ' element-error-highlight' : ''}`}
                            name='select-fund'
                            data-code={pledge.fundCode}
                            data-tracking={pledge.trackingId}
                            onchange={handleChange}>
                            {selectControlOptions.placeHolderOptions.hasPlaceholder &&
                                <option
                                    className={selectControlOptions.placeHolderOptions.classes}
                                    selected={pledge.fundId === selectControlOptions.placeHolderOptions.value ? 'selected' : '' }
                                    value={selectControlOptions.placeHolderOptions.value}
                                    disabled>
                                    {selectControlOptions.placeHolderOptions.label}
                                </option> 
                            }
                            {
                                selectControlOptions.options.map(option => {
                                    return <option
                                        selected={option.fundId === pledge.fundId}
                                        data-fundname={option.fundName}
                                        value={option.fundId}>{option.fundName}
                                    </option>
                                })
                            }
                        </select>
                        <div className={`field-error${submitted && pledgeErrors && pledgeErrors.errors.fundId ? ' error-show': ' error-hide'}`}>Please Select Fund</div>
                    </div>
                    <div>
                        <div className={`pledge-label${pledgeCount > 0 ? ' not-first' : ''}`}>{currencyOptions.label}</div>
                        <div className='d-flex align-base'>
                            <span className='money-font'>$</span>
                            <input
                                className={`m-0${submitted && pledgeErrors && pledgeErrors.errors.amount ? ' element-error-highlight' : ''}`}
                                type='text'
                                placeholder="0.00"
                                name="input-pledge-amount"
                                pattern={"[0-9]+"}
                                value={pledge.donationAmount ? pledge.donationAmount.toFixed(2) : '0.00'}
                                data-code={pledge.fundCode}
                                data-current={pledge.fundId}
                                disabled={isNullOrWhitespace(pledge.fundId) || ['-2', '-1', '0'].includes(pledge.fundId)}
                                onblur={handleChange}>
                            </input>
                        </div>
                        <div className={`field-error${submitted && pledgeErrors && pledgeErrors.errors.amount ? ' error-show' : ' error-hide'}`}>Please Enter Valid Dollar Amount</div>
                    </div>
                    <div>
                        <div className={`pledge-label-blank${pledgeCount > 0 ? ' not-first' : ''}`}></div>
                        <div>
                            <ActionButtonLink
                                classes={removePledgeControlOptions.classes}
                                icon={removePledgeControlOptions.icon}
                                text={removePledgeControlOptions.text}
                                dataAttributes={{
                                    code: pledge.fundCode,
                                    current: pledge.fundId,
                                    priority: false,
                                    custom: false
                                }}
                                actionType={removePledgeControlOptions.actionType}
                                handleClick={handleClick}
                            />
                        </div>
                    </div>                
                </div>
            </div>            
        </Fragment>
    )
}

export const WeGiveFakeSelectPledgeRow = ({
    pledgeCount,
    pledge,
    countOffset,
    fakeSelectControlOptions,
    currencyOptions,
    removePledgeControlOptions,
    handleChange,
    handleClick,
    pledgeErrors,
    submitted }) => {
    return (
        <Fragment>
            {pledgeCount > 0 && <ItemSeparator width='80%' margin='25px auto' />}
            <div>
                <div className='pledgeGroup d-flex'>
                    <div>
                        <div className={`pledge-label${pledgeCount > 0 ? ' not-first' : ''}`}>
                            <div className='d-flex'>
                                <span>{fakeSelectControlOptions.label}</span>
                                <span className='label-count'>&nbsp;({pledgeCount + 1})</span>
                            </div>
                        </div>
                        <div
                            className={`fake-select truncate${submitted && pledgeErrors && pledgeErrors.errors.fundId ? ' element-error-highlight' : ''}`}
                            tabIndex='0'
                            data-action='fake-select'
                            data-code={pledge.fundCode}
                            data-custom={true}
                            data-tracking={pledge.trackingId}
                            onclick={handleClick}
                        >
                            {isNullOrWhitespace(pledge.fundId) || ['-1', '0'].includes(pledge.fundId) ? fakeSelectControlOptions.placeholder : pledge.fundName}
                        </div>
                        <div className={`field-error${submitted && pledgeErrors && pledgeErrors.errors.fundId ? ' error-show' : ' error-hide'}`}>Please Select Fund</div>
                    </div>
                    <div>
                        <div className={`pledge-label${pledgeCount > 0 ? ' not-first' : ''}`}>{currencyOptions.label}</div>
                        <div className='d-flex align-base'>
                            <span className='money-font'>$</span>
                            <input
                                className={`m-0${submitted && pledgeErrors && pledgeErrors.errors.amount ? ' element-error-highlight' : ''}`}
                                type='text'
                                placeholder="0.00"
                                name="input-pledge-amount"
                                pattern={"[0-9]+"}
                                value={pledge.donationAmount ? pledge.donationAmount.toFixed(2) : '0.00'}
                                data-code={pledge.fundCode}
                                data-current={pledge.fundId}
                                disabled={isNullOrWhitespace(pledge.fundId) || ['-1', '0'].includes(pledge.fundId)}
                                onblur={handleChange}>
                            </input>
                        </div>
                        <div className={`field-error${submitted && pledgeErrors && pledgeErrors.errors.amount ? ' error-show' : ' error-hide'}`}>Please Enter Valid Dollar Amount</div>
                    </div>
                    <div>
                        <div className={`pledge-label-blank${pledgeCount > 0 ? ' not-first' : ''}`}></div>
                        <div>
                            <ActionButtonLink
                                classes={removePledgeControlOptions.classes}
                                icon={removePledgeControlOptions.icon}
                                text={removePledgeControlOptions.text}
                                dataAttributes={{
                                    code: pledge.fundCode,
                                    current: pledge.fundId,
                                    priority: false,
                                    custom: false
                                }}
                                actionType={removePledgeControlOptions.actionType}
                                handleClick={handleClick}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export const WeGiveCustomPledgeRow = ({ countOffset, pledgeCount, controlOptions, removePledgeControlOptions, deductionIntervalControlOptions, pledge, handleChange, handleClick, pledgeErrors, submitted }) => {
    return (
        <Fragment>
            {pledgeCount > 1 && <ItemSeparator width='80%' margin='25px auto' />}
            <div>
                <div className='controlGroup d-flex'>
                    <div>
                        <div className='pledgeGroup d-flex'>
                            <div>
                                <div className={`pledge-label`}>
                                    <div className='d-flex custom-uw-pledge-label'>
                                        <span>{controlOptions.agencyLabel}</span>
                                        <span className='label-count'>&nbsp;({pledgeCount + countOffset})</span>
                                    </div>
                                    <input
                                        className={`m-0 custom-uw-pledge${submitted && pledgeErrors && pledgeErrors.errors.fundName ? ' element-error-highlight' : ''}`}
                                        type='text'
                                        placeholder={controlOptions.agencyPlaceholder}
                                        name='input-custom-fund-name'                                        
                                        data-current={pledge.fundId}
                                        data-code={pledge.fundCode}
                                        data-tracking={pledge.trackingId}
                                        value={pledge.fundName}
                                        onblur={handleChange}
                                    />
                                    <div className={`field-error${submitted && pledgeErrors && pledgeErrors.errors.fundName ? ' error-show' : ' error-hide'}`}>Please Enter a Charity Name</div>
                                </div>
                            </div>                    
                            <div>
                                <div className={`pledge-label custom-uw-pledge-label`}>{controlOptions.currencyLabel}</div>
                                <div className='d-flex align-base'>
                                    <span className='money-font'>$</span>
                                    <input
                                        className={`m-0${submitted && pledgeErrors && pledgeErrors.errors.amount ? ' element-error-highlight' : ''}`}
                                        type='text'
                                        placeholder="0.00"
                                        name="input-custom-pledge-amount"
                                        pattern="[0-9]+"
                                        value={pledge.donationAmount ? pledge.donationAmount.toFixed(2) : '0.00'}
                                        data-code={pledge.fundCode}
                                        data-current={pledge.fundId}
                                        data-tracking={pledge.trackingId}
                                        onchange={handleChange}
                                    />
                                </div>
                                <div className={`field-error${submitted && pledgeErrors && pledgeErrors.errors.amount ? ' error-show' : ' error-hide'}`}>Please Enter Valid Dollar Amount</div>
                            </div>
                        </div>
                        <div className='control-group-mt'>
                            <div>
                                <div className={`pledge-label custom-uw-pledge-label`}>{controlOptions.agencyIdLabel}</div>
                                <input
                                    className={`m-0 custom-uw-pledge${submitted && pledgeErrors && pledgeErrors.errors.fundId ? ' element-error-highlight' : ''}`}
                                    type='text'
                                    name='input-custom-fund-id'
                                    placeholder={controlOptions.agencyIdPlaceholder}
                                    value={['-1', '0'].includes(pledge.fundId) ? '' : pledge.fundId}
                                    data-code={pledge.fundCode}
                                    data-current={pledge.fundId}
                                    data-tracking={pledge.trackingId}
                                    onchange={handleChange}
                                />
                                <div className={`field-error${submitted && pledgeErrors && pledgeErrors.errors.fundId ? ' error-show' : ' error-hide'}`}>Please Enter a Valid EIN (ex: 00-0000000)</div>
                            </div>
                        </div>
                    </div>
                    <ActionButtonLink
                        classes={removePledgeControlOptions.classes}
                        icon={removePledgeControlOptions.icon}
                        text={removePledgeControlOptions.text}
                        actionType={removePledgeControlOptions.actionType}
                        handleClick={handleClick}
                        isCustom={true}
                        dataAttributes={{
                            code: pledge.fundCode,
                            current: pledge.fundId,
                            priority: false,
                            custom: false
                        }}
                    />
                </div>
            </div>
        </Fragment>
    )
}